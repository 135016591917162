export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const QRCodeComponent = () => import('../../components/QRCodeComponent.vue' /* webpackChunkName: "components/q-r-code-component" */).then(c => wrapFunctional(c.default || c))
export const SideBar = () => import('../../components/SideBar.vue' /* webpackChunkName: "components/side-bar" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const VuetifyLogo = () => import('../../components/VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalDuplicateProps = () => import('../../components/Modals/ModalDuplicateProps.vue' /* webpackChunkName: "components/modals-modal-duplicate-props" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalPostComments = () => import('../../components/Modals/ModalPostComments.vue' /* webpackChunkName: "components/modals-modal-post-comments" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalPostVideoReviews = () => import('../../components/Modals/ModalPostVideoReviews.vue' /* webpackChunkName: "components/modals-modal-post-video-reviews" */).then(c => wrapFunctional(c.default || c))
export const ZoneName = () => import('../../components/Zone/ZoneName.vue' /* webpackChunkName: "components/zone-name" */).then(c => wrapFunctional(c.default || c))
export const ZoneEditZone = () => import('../../components/Zone/editZone.vue' /* webpackChunkName: "components/zone-edit-zone" */).then(c => wrapFunctional(c.default || c))
export const ChartsBarChart = () => import('../../components/charts/BarChart.vue' /* webpackChunkName: "components/charts-bar-chart" */).then(c => wrapFunctional(c.default || c))
export const ComingSoonItemL = () => import('../../components/comingSoon/ComingSoonItemL.vue' /* webpackChunkName: "components/coming-soon-item-l" */).then(c => wrapFunctional(c.default || c))
export const ComingSoonItemM = () => import('../../components/comingSoon/ComingSoonItemM.vue' /* webpackChunkName: "components/coming-soon-item-m" */).then(c => wrapFunctional(c.default || c))
export const ComingSoonItemS = () => import('../../components/comingSoon/ComingSoonItemS.vue' /* webpackChunkName: "components/coming-soon-item-s" */).then(c => wrapFunctional(c.default || c))
export const DesktopImageGallery = () => import('../../components/desktop/ImageGallery.vue' /* webpackChunkName: "components/desktop-image-gallery" */).then(c => wrapFunctional(c.default || c))
export const DraganddropDataTableRowHandler = () => import('../../components/draganddrop/DataTableRowHandler.vue' /* webpackChunkName: "components/draganddrop-data-table-row-handler" */).then(c => wrapFunctional(c.default || c))
export const DraganddropLogoImageEdit = () => import('../../components/draganddrop/logoImage-edit.vue' /* webpackChunkName: "components/draganddrop-logo-image-edit" */).then(c => wrapFunctional(c.default || c))
export const DraganddropLogoImage = () => import('../../components/draganddrop/logoImage.vue' /* webpackChunkName: "components/draganddrop-logo-image" */).then(c => wrapFunctional(c.default || c))
export const DraganddropProfileImageEdit = () => import('../../components/draganddrop/profileImage-edit.vue' /* webpackChunkName: "components/draganddrop-profile-image-edit" */).then(c => wrapFunctional(c.default || c))
export const DraganddropProfileImage = () => import('../../components/draganddrop/profileImage.vue' /* webpackChunkName: "components/draganddrop-profile-image" */).then(c => wrapFunctional(c.default || c))
export const EnrollsEnrollExampleDesk = () => import('../../components/enrolls/EnrollExampleDesk.vue' /* webpackChunkName: "components/enrolls-enroll-example-desk" */).then(c => wrapFunctional(c.default || c))
export const EnrollsEnrollExampleMobile = () => import('../../components/enrolls/EnrollExampleMobile.vue' /* webpackChunkName: "components/enrolls-enroll-example-mobile" */).then(c => wrapFunctional(c.default || c))
export const EnrollsEnrollExampleTablet = () => import('../../components/enrolls/EnrollExampleTablet.vue' /* webpackChunkName: "components/enrolls-enroll-example-tablet" */).then(c => wrapFunctional(c.default || c))
export const Error = () => import('../../components/error/403.vue' /* webpackChunkName: "components/error" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineFirstStep = () => import('../../components/event-online/FirstStep.vue' /* webpackChunkName: "components/event-online-first-step" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineFormActionButtons = () => import('../../components/event-online/FormActionButtons.vue' /* webpackChunkName: "components/event-online-form-action-buttons" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineFourthStep = () => import('../../components/event-online/FourthStep.vue' /* webpackChunkName: "components/event-online-fourth-step" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSecondStep = () => import('../../components/event-online/SecondStep.vue' /* webpackChunkName: "components/event-online-second-step" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineStepper = () => import('../../components/event-online/Stepper.vue' /* webpackChunkName: "components/event-online-stepper" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineThirdStep = () => import('../../components/event-online/ThirdStep.vue' /* webpackChunkName: "components/event-online-third-step" */).then(c => wrapFunctional(c.default || c))
export const ExternalPostInProjectEdit = () => import('../../components/externalPost/InProject-edit.vue' /* webpackChunkName: "components/external-post-in-project-edit" */).then(c => wrapFunctional(c.default || c))
export const FeaturecommentCommentBox = () => import('../../components/featurecomment/CommentBox.vue' /* webpackChunkName: "components/featurecomment-comment-box" */).then(c => wrapFunctional(c.default || c))
export const FeaturecommentModalComment = () => import('../../components/featurecomment/ModalComment.vue' /* webpackChunkName: "components/featurecomment-modal-comment" */).then(c => wrapFunctional(c.default || c))
export const Guideline = () => import('../../components/guideline/Guideline.vue' /* webpackChunkName: "components/guideline" */).then(c => wrapFunctional(c.default || c))
export const PromoInHousesPreviewImage = () => import('../../components/promoInHouses/previewImage.vue' /* webpackChunkName: "components/promo-in-houses-preview-image" */).then(c => wrapFunctional(c.default || c))
export const PromoTag = () => import('../../components/promoInHouses/promoTag.vue' /* webpackChunkName: "components/promo-tag" */).then(c => wrapFunctional(c.default || c))
export const PromoInHousesPronayooEventCard = () => import('../../components/promoInHouses/pronayooEventCard.vue' /* webpackChunkName: "components/promo-in-houses-pronayoo-event-card" */).then(c => wrapFunctional(c.default || c))
export const ReferPreviewFile = () => import('../../components/refer/previewFile.vue' /* webpackChunkName: "components/refer-preview-file" */).then(c => wrapFunctional(c.default || c))
export const PostsAddressEdit = () => import('../../components/posts/Address-edit.vue' /* webpackChunkName: "components/posts-address-edit" */).then(c => wrapFunctional(c.default || c))
export const PostsInProjectEdit = () => import('../../components/posts/InProject-edit.vue' /* webpackChunkName: "components/posts-in-project-edit" */).then(c => wrapFunctional(c.default || c))
export const PostsNumberOf = () => import('../../components/posts/NumberOf.vue' /* webpackChunkName: "components/posts-number-of" */).then(c => wrapFunctional(c.default || c))
export const PostsTypeSelect = () => import('../../components/posts/TypeSelect.vue' /* webpackChunkName: "components/posts-type-select" */).then(c => wrapFunctional(c.default || c))
export const PostsYoutubeItem = () => import('../../components/posts/YoutubeItem.vue' /* webpackChunkName: "components/posts-youtube-item" */).then(c => wrapFunctional(c.default || c))
export const PostsYoutubeItemHomeBuilder = () => import('../../components/posts/YoutubeItemHomeBuilder.vue' /* webpackChunkName: "components/posts-youtube-item-home-builder" */).then(c => wrapFunctional(c.default || c))
export const PostsAccomApartment = () => import('../../components/posts/accom-apartment.vue' /* webpackChunkName: "components/posts-accom-apartment" */).then(c => wrapFunctional(c.default || c))
export const PostsAccommodations = () => import('../../components/posts/accommodations.vue' /* webpackChunkName: "components/posts-accommodations" */).then(c => wrapFunctional(c.default || c))
export const PostsAttachmentsMain = () => import('../../components/posts/attachmentsMain.vue' /* webpackChunkName: "components/posts-attachments-main" */).then(c => wrapFunctional(c.default || c))
export const PostsBrochureCreate = () => import('../../components/posts/brochure-create.vue' /* webpackChunkName: "components/posts-brochure-create" */).then(c => wrapFunctional(c.default || c))
export const PostsBrochureEdit = () => import('../../components/posts/brochure-edit.vue' /* webpackChunkName: "components/posts-brochure-edit" */).then(c => wrapFunctional(c.default || c))
export const PostsExpensesApartment = () => import('../../components/posts/expenses-apartment.vue' /* webpackChunkName: "components/posts-expenses-apartment" */).then(c => wrapFunctional(c.default || c))
export const PostsHousePlanCreate = () => import('../../components/posts/housePlan-create.vue' /* webpackChunkName: "components/posts-house-plan-create" */).then(c => wrapFunctional(c.default || c))
export const PostsHousePlanEdit = () => import('../../components/posts/housePlan-edit.vue' /* webpackChunkName: "components/posts-house-plan-edit" */).then(c => wrapFunctional(c.default || c))
export const PostsMainImage = () => import('../../components/posts/mainImage.vue' /* webpackChunkName: "components/posts-main-image" */).then(c => wrapFunctional(c.default || c))
export const PostsMainImageHomeBuilder = () => import('../../components/posts/mainImageHomeBuilder.vue' /* webpackChunkName: "components/posts-main-image-home-builder" */).then(c => wrapFunctional(c.default || c))
export const PostsMapRoute = () => import('../../components/posts/map-route.vue' /* webpackChunkName: "components/posts-map-route" */).then(c => wrapFunctional(c.default || c))
export const PostsApartmentCreate = () => import('../../components/posts/postsApartment-create.vue' /* webpackChunkName: "components/posts-apartment-create" */).then(c => wrapFunctional(c.default || c))
export const PostsApartmentEdit = () => import('../../components/posts/postsApartment-edit.vue' /* webpackChunkName: "components/posts-apartment-edit" */).then(c => wrapFunctional(c.default || c))
export const PostsPromotionCreate = () => import('../../components/posts/promotion-create.vue' /* webpackChunkName: "components/posts-promotion-create" */).then(c => wrapFunctional(c.default || c))
export const PostsPromotionEdit = () => import('../../components/posts/promotion-edit.vue' /* webpackChunkName: "components/posts-promotion-edit" */).then(c => wrapFunctional(c.default || c))
export const PostsPromotionHomeBuilder = () => import('../../components/posts/promotionHomeBuilder.vue' /* webpackChunkName: "components/posts-promotion-home-builder" */).then(c => wrapFunctional(c.default || c))
export const PostsSubImagesCreate = () => import('../../components/posts/subImages-create.vue' /* webpackChunkName: "components/posts-sub-images-create" */).then(c => wrapFunctional(c.default || c))
export const PostsSubImagesEdit = () => import('../../components/posts/subImages-edit.vue' /* webpackChunkName: "components/posts-sub-images-edit" */).then(c => wrapFunctional(c.default || c))
export const PostsVideosCreate = () => import('../../components/posts/videos-create.vue' /* webpackChunkName: "components/posts-videos-create" */).then(c => wrapFunctional(c.default || c))
export const PostsVideosEdit = () => import('../../components/posts/videos-edit.vue' /* webpackChunkName: "components/posts-videos-edit" */).then(c => wrapFunctional(c.default || c))
export const PostsVideosHomeBuilder = () => import('../../components/posts/videosHomeBuilder.vue' /* webpackChunkName: "components/posts-videos-home-builder" */).then(c => wrapFunctional(c.default || c))
export const SharesCardCompany = () => import('../../components/shares/CardCompany.vue' /* webpackChunkName: "components/shares-card-company" */).then(c => wrapFunctional(c.default || c))
export const SharesCardPostSecondHand = () => import('../../components/shares/CardPostSecondHand.vue' /* webpackChunkName: "components/shares-card-post-second-hand" */).then(c => wrapFunctional(c.default || c))
export const SharesCardProduct = () => import('../../components/shares/CardProduct.vue' /* webpackChunkName: "components/shares-card-product" */).then(c => wrapFunctional(c.default || c))
export const SharesCardProductOld = () => import('../../components/shares/CardProductOld.vue' /* webpackChunkName: "components/shares-card-product-old" */).then(c => wrapFunctional(c.default || c))
export const SharesCountdown = () => import('../../components/shares/Countdown.vue' /* webpackChunkName: "components/shares-countdown" */).then(c => wrapFunctional(c.default || c))
export const SharesFormUpload = () => import('../../components/shares/FormUpload.vue' /* webpackChunkName: "components/shares-form-upload" */).then(c => wrapFunctional(c.default || c))
export const SharesGradientCplorPicker = () => import('../../components/shares/GradientCplorPicker.vue' /* webpackChunkName: "components/shares-gradient-cplor-picker" */).then(c => wrapFunctional(c.default || c))
export const SharesPreviewUpload = () => import('../../components/shares/PreviewUpload.vue' /* webpackChunkName: "components/shares-preview-upload" */).then(c => wrapFunctional(c.default || c))
export const SharesTextInput = () => import('../../components/shares/TextInput.vue' /* webpackChunkName: "components/shares-text-input" */).then(c => wrapFunctional(c.default || c))
export const SharesUploadFile = () => import('../../components/shares/UploadFile.vue' /* webpackChunkName: "components/shares-upload-file" */).then(c => wrapFunctional(c.default || c))
export const SharesUploadFileV2 = () => import('../../components/shares/UploadFileV2.vue' /* webpackChunkName: "components/shares-upload-file-v2" */).then(c => wrapFunctional(c.default || c))
export const SharesUploadImage = () => import('../../components/shares/UploadImage.vue' /* webpackChunkName: "components/shares-upload-image" */).then(c => wrapFunctional(c.default || c))
export const SharesZoneItem = () => import('../../components/shares/ZoneItem.vue' /* webpackChunkName: "components/shares-zone-item" */).then(c => wrapFunctional(c.default || c))
export const TrackingDataTable = () => import('../../components/tracking/DataTable.vue' /* webpackChunkName: "components/tracking-data-table" */).then(c => wrapFunctional(c.default || c))
export const ZonesZoneName = () => import('../../components/zones/ZoneName.vue' /* webpackChunkName: "components/zones-zone-name" */).then(c => wrapFunctional(c.default || c))
export const ZonesUpdateText = () => import('../../components/zones/updateText.vue' /* webpackChunkName: "components/zones-update-text" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleAgreementCancelRequest = () => import('../../components/Baanprompt/SaleAgreement/CancelRequest.vue' /* webpackChunkName: "components/baanprompt-sale-agreement-cancel-request" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleAgreementContractGallary = () => import('../../components/Baanprompt/SaleAgreement/ContractGallary.vue' /* webpackChunkName: "components/baanprompt-sale-agreement-contract-gallary" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleAgreementDialogSaleAgreement = () => import('../../components/Baanprompt/SaleAgreement/DialogSaleAgreement.vue' /* webpackChunkName: "components/baanprompt-sale-agreement-dialog-sale-agreement" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleAgreementHistory = () => import('../../components/Baanprompt/SaleAgreement/History.vue' /* webpackChunkName: "components/baanprompt-sale-agreement-history" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleAgreementHistoryOrder = () => import('../../components/Baanprompt/SaleAgreement/HistoryOrder.vue' /* webpackChunkName: "components/baanprompt-sale-agreement-history-order" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleAgreementReferInfo = () => import('../../components/Baanprompt/SaleAgreement/ReferInfo.vue' /* webpackChunkName: "components/baanprompt-sale-agreement-refer-info" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleAgreementResultAppointment = () => import('../../components/Baanprompt/SaleAgreement/ResultAppointment.vue' /* webpackChunkName: "components/baanprompt-sale-agreement-result-appointment" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReportContactFromAmount = () => import('../../components/Baanprompt/SaleReport/ContactFromAmount.vue' /* webpackChunkName: "components/baanprompt-sale-report-contact-from-amount" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReportContactFromTag = () => import('../../components/Baanprompt/SaleReport/ContactFromTag.vue' /* webpackChunkName: "components/baanprompt-sale-report-contact-from-tag" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReportDateSort = () => import('../../components/Baanprompt/SaleReport/DateSort.vue' /* webpackChunkName: "components/baanprompt-sale-report-date-sort" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReportDateSortInterest = () => import('../../components/Baanprompt/SaleReport/DateSortInterest.vue' /* webpackChunkName: "components/baanprompt-sale-report-date-sort-interest" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReportDialogMain = () => import('../../components/Baanprompt/SaleReport/DialogMain.vue' /* webpackChunkName: "components/baanprompt-sale-report-dialog-main" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReportInterestTags = () => import('../../components/Baanprompt/SaleReport/InterestTags.vue' /* webpackChunkName: "components/baanprompt-sale-report-interest-tags" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReportLocationAnalysTags = () => import('../../components/Baanprompt/SaleReport/LocationAnalysTags.vue' /* webpackChunkName: "components/baanprompt-sale-report-location-analys-tags" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReportMultiFilterInterest = () => import('../../components/Baanprompt/SaleReport/MultiFilterInterest.vue' /* webpackChunkName: "components/baanprompt-sale-report-multi-filter-interest" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReportMultiFilterMonthly = () => import('../../components/Baanprompt/SaleReport/MultiFilterMonthly.vue' /* webpackChunkName: "components/baanprompt-sale-report-multi-filter-monthly" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReportMultiFilterVisit = () => import('../../components/Baanprompt/SaleReport/MultiFilterVisit.vue' /* webpackChunkName: "components/baanprompt-sale-report-multi-filter-visit" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReportPriceAnalysTags = () => import('../../components/Baanprompt/SaleReport/PriceAnalysTags.vue' /* webpackChunkName: "components/baanprompt-sale-report-price-analys-tags" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReport = () => import('../../components/Baanprompt/SaleReport/Report.vue' /* webpackChunkName: "components/baanprompt-sale-report" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReportReports = () => import('../../components/Baanprompt/SaleReport/Reports.vue' /* webpackChunkName: "components/baanprompt-sale-report-reports" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSaleReportTabs = () => import('../../components/Baanprompt/SaleReport/Tabs.vue' /* webpackChunkName: "components/baanprompt-sale-report-tabs" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSharesConfirmDialog = () => import('../../components/Baanprompt/Shares/ConfirmDialog.vue' /* webpackChunkName: "components/baanprompt-shares-confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSharesDateFilter = () => import('../../components/Baanprompt/Shares/DateFilter.vue' /* webpackChunkName: "components/baanprompt-shares-date-filter" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSharesExportBtn = () => import('../../components/Baanprompt/Shares/ExportBtn.vue' /* webpackChunkName: "components/baanprompt-shares-export-btn" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSharesRejectReasonDialog = () => import('../../components/Baanprompt/Shares/RejectReasonDialog.vue' /* webpackChunkName: "components/baanprompt-shares-reject-reason-dialog" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptSharesSort = () => import('../../components/Baanprompt/Shares/Sort.vue' /* webpackChunkName: "components/baanprompt-shares-sort" */).then(c => wrapFunctional(c.default || c))
export const ChartsShortUrlBarChartData = () => import('../../components/charts/shortUrl/BarChartData.vue' /* webpackChunkName: "components/charts-short-url-bar-chart-data" */).then(c => wrapFunctional(c.default || c))
export const ChartsShortUrlDoughChartData1 = () => import('../../components/charts/shortUrl/DoughChartData1.vue' /* webpackChunkName: "components/charts-short-url-dough-chart-data1" */).then(c => wrapFunctional(c.default || c))
export const ChartsShortUrlDoughChartData2 = () => import('../../components/charts/shortUrl/DoughChartData2.vue' /* webpackChunkName: "components/charts-short-url-dough-chart-data2" */).then(c => wrapFunctional(c.default || c))
export const ChartsShortUrlLineChartData = () => import('../../components/charts/shortUrl/LineChartData.vue' /* webpackChunkName: "components/charts-short-url-line-chart-data" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptTransferenceContractGallary = () => import('../../components/Baanprompt/Transference/ContractGallary.vue' /* webpackChunkName: "components/baanprompt-transference-contract-gallary" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptTransferenceDetails = () => import('../../components/Baanprompt/Transference/Details.vue' /* webpackChunkName: "components/baanprompt-transference-details" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptTransferenceDialogMain = () => import('../../components/Baanprompt/Transference/DialogMain.vue' /* webpackChunkName: "components/baanprompt-transference-dialog-main" */).then(c => wrapFunctional(c.default || c))
export const BaanpromptTransferenceReferInfo = () => import('../../components/Baanprompt/Transference/ReferInfo.vue' /* webpackChunkName: "components/baanprompt-transference-refer-info" */).then(c => wrapFunctional(c.default || c))
export const SharesAgenthomeV2FilesUpload = () => import('../../components/shares/agenthomeV2/FilesUpload.vue' /* webpackChunkName: "components/shares-agenthome-v2-files-upload" */).then(c => wrapFunctional(c.default || c))
export const SharesAgenthomeV2ImageUpload = () => import('../../components/shares/agenthomeV2/ImageUpload.vue' /* webpackChunkName: "components/shares-agenthome-v2-image-upload" */).then(c => wrapFunctional(c.default || c))
export const SharesAgenthomeV2MultiImagesUpload = () => import('../../components/shares/agenthomeV2/MultiImagesUpload.vue' /* webpackChunkName: "components/shares-agenthome-v2-multi-images-upload" */).then(c => wrapFunctional(c.default || c))
export const SharesUiBreadcrumbs = () => import('../../components/shares/ui/Breadcrumbs.vue' /* webpackChunkName: "components/shares-ui-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const SharesUiYoutubeEmbed = () => import('../../components/shares/ui/YoutubeEmbed.vue' /* webpackChunkName: "components/shares-ui-youtube-embed" */).then(c => wrapFunctional(c.default || c))
export const CustomersAgentsRegisterSelectBtn = () => import('../../components/customers/agents/register/SelectBtn.vue' /* webpackChunkName: "components/customers-agents-register-select-btn" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSharesDevicePreview = () => import('../../components/event-online/shares/DevicePreview.vue' /* webpackChunkName: "components/event-online-shares-device-preview" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSharesMultiplePreviewImage = () => import('../../components/event-online/shares/MultiplePreviewImage.vue' /* webpackChunkName: "components/event-online-shares-multiple-preview-image" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSharesPreviewImage = () => import('../../components/event-online/shares/PreviewImage.vue' /* webpackChunkName: "components/event-online-shares-preview-image" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSharesUploadImageButton = () => import('../../components/event-online/shares/UploadImageButton.vue' /* webpackChunkName: "components/event-online-shares-upload-image-button" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSharesUploadImageCollections = () => import('../../components/event-online/shares/UploadImageCollections.vue' /* webpackChunkName: "components/event-online-shares-upload-image-collections" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSharesWarningImageDialog = () => import('../../components/event-online/shares/WarningImageDialog.vue' /* webpackChunkName: "components/event-online-shares-warning-image-dialog" */).then(c => wrapFunctional(c.default || c))
export const CustomersHomeBuilderShareAddFile = () => import('../../components/customers/home-builder/share/AddFile.vue' /* webpackChunkName: "components/customers-home-builder-share-add-file" */).then(c => wrapFunctional(c.default || c))
export const CustomersHomeBuilderShareUploadFile = () => import('../../components/customers/home-builder/share/UploadFile.vue' /* webpackChunkName: "components/customers-home-builder-share-upload-file" */).then(c => wrapFunctional(c.default || c))
export const CustomersHomeBuilderShareUploadMainImage = () => import('../../components/customers/home-builder/share/UploadMainImage.vue' /* webpackChunkName: "components/customers-home-builder-share-upload-main-image" */).then(c => wrapFunctional(c.default || c))
export const CustomersHomeBuilderShareUploadMultiImage = () => import('../../components/customers/home-builder/share/UploadMultiImage.vue' /* webpackChunkName: "components/customers-home-builder-share-upload-multi-image" */).then(c => wrapFunctional(c.default || c))
export const CustomersHomeBuilderShareUploadProfile = () => import('../../components/customers/home-builder/share/UploadProfile.vue' /* webpackChunkName: "components/customers-home-builder-share-upload-profile" */).then(c => wrapFunctional(c.default || c))
export const CustomersHomeBuilderShareUploadVideo = () => import('../../components/customers/home-builder/share/UploadVideo.vue' /* webpackChunkName: "components/customers-home-builder-share-upload-video" */).then(c => wrapFunctional(c.default || c))
export const DashboardRefersSendPostStatus = () => import('../../components/dashboard/refers/send/PostStatus.vue' /* webpackChunkName: "components/dashboard-refers-send-post-status" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsFirstStepDetailForm = () => import('../../components/event-online/sections/first-step/DetailForm.vue' /* webpackChunkName: "components/event-online-sections-first-step-detail-form" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsFirstStepMainSponsorForm = () => import('../../components/event-online/sections/first-step/MainSponsorForm.vue' /* webpackChunkName: "components/event-online-sections-first-step-main-sponsor-form" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsFirstStepMapForm = () => import('../../components/event-online/sections/first-step/MapForm.vue' /* webpackChunkName: "components/event-online-sections-first-step-map-form" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsFirstStepPlanForm = () => import('../../components/event-online/sections/first-step/PlanForm.vue' /* webpackChunkName: "components/event-online-sections-first-step-plan-form" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsFirstStepSubSponsorForm = () => import('../../components/event-online/sections/first-step/SubSponsorForm.vue' /* webpackChunkName: "components/event-online-sections-first-step-sub-sponsor-form" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsFirstStepVideoForm = () => import('../../components/event-online/sections/first-step/VideoForm.vue' /* webpackChunkName: "components/event-online-sections-first-step-video-form" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsSecondStepButtonColorForm = () => import('../../components/event-online/sections/second-step/ButtonColorForm.vue' /* webpackChunkName: "components/event-online-sections-second-step-button-color-form" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsSecondStepEndEventForm = () => import('../../components/event-online/sections/second-step/EndEventForm.vue' /* webpackChunkName: "components/event-online-sections-second-step-end-event-form" */).then(c => wrapFunctional(c.default || c))
export const EventForm = () => import('../../components/event-online/sections/second-step/EventForm.vue' /* webpackChunkName: "components/event-form" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsSecondStepLogoForm = () => import('../../components/event-online/sections/second-step/LogoForm.vue' /* webpackChunkName: "components/event-online-sections-second-step-logo-form" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsSecondStepPreEventForm = () => import('../../components/event-online/sections/second-step/PreEventForm.vue' /* webpackChunkName: "components/event-online-sections-second-step-pre-event-form" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsSecondStepPromoteForm = () => import('../../components/event-online/sections/second-step/PromoteForm.vue' /* webpackChunkName: "components/event-online-sections-second-step-promote-form" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsThirdStepAddHomeBuilderDialog = () => import('../../components/event-online/sections/third-step/AddHomeBuilderDialog.vue' /* webpackChunkName: "components/event-online-sections-third-step-add-home-builder-dialog" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsThirdStepAddPostsDialog = () => import('../../components/event-online/sections/third-step/AddPostsDialog.vue' /* webpackChunkName: "components/event-online-sections-third-step-add-posts-dialog" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsThirdStepJoinApartment = () => import('../../components/event-online/sections/third-step/JoinApartment.vue' /* webpackChunkName: "components/event-online-sections-third-step-join-apartment" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsThirdStepJoinHomeBuilder = () => import('../../components/event-online/sections/third-step/JoinHomeBuilder.vue' /* webpackChunkName: "components/event-online-sections-third-step-join-home-builder" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsThirdStepJoinProject = () => import('../../components/event-online/sections/third-step/JoinProject.vue' /* webpackChunkName: "components/event-online-sections-third-step-join-project" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsThirdStepJoinSecondhand = () => import('../../components/event-online/sections/third-step/JoinSecondhand.vue' /* webpackChunkName: "components/event-online-sections-third-step-join-secondhand" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsThirdStepPromotionsForm = () => import('../../components/event-online/sections/third-step/PromotionsForm.vue' /* webpackChunkName: "components/event-online-sections-third-step-promotions-form" */).then(c => wrapFunctional(c.default || c))
export const EventOnlineSectionsThirdStepVideoPromoteDialog = () => import('../../components/event-online/sections/third-step/VideoPromoteDialog.vue' /* webpackChunkName: "components/event-online-sections-third-step-video-promote-dialog" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
